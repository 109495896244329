import { getRequestConfig } from 'next-intl/server';

export default getRequestConfig(async ({ locale }) => ({
  messages: (await import(`../messages/${locale}.json`)).default,
}));

/**
 * @constant LOCALES
 * @description list of all locales that are supported
 * First locale is used as the default one
 */
export const LOCALES = ['en', 'cs', 'pl', 'de', 'fr', 'es', 'it', 'ja'];

export const LOCALE_TO_LANGUAGE = {
  en: 'English',
  cs: 'Čeština',
  pl: 'Polski',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  ja: '日本語',
};

export const DEFAULT_LOCALE = LOCALES[0];

// eslint-disable-next-line prettier/prettier
export type LocaleType = typeof LOCALES[number];
