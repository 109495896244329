'use client';
import React from 'react';

import Switch from '@mui/material/Switch';
import { usePathname } from 'next/navigation';
import styled from 'styled-components';

import { useBreakpoint } from '@/hooks/useBreakpoint';
import persistentState, { PERSISTENT_STATE_KEYS } from '@/lib/persistentState';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`;

const BlinkingText = styled.span<{ isPreviewModeActive: boolean }>`
  animation: ${({ isPreviewModeActive }) =>
    isPreviewModeActive ? 'blink 2s infinite' : 'none'};

  @keyframes blink {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
`;

const PREVIEW_ENABLED_PATHS = [
  '/product',
  '/category',
  '/article',
  '/guide',
  '/manual',
];

const isPathEnabled = (pathname: string) => {
  if (!pathname) {
    return false;
  }

  if (pathname === '/') {
    return true;
  }

  return PREVIEW_ENABLED_PATHS.some(path => pathname.startsWith(path));
};

export default function PreviewModeSwitch() {
  const [switchOn, setSwitchOn] = React.useState(false);
  const pathname = usePathname();
  const breakpoint = useBreakpoint();

  const isPreviewModeActive = React.useMemo(() => {
    const previewModeCookie = persistentState.cookie.get({
      key: 'preview-mode',
    });

    if (!previewModeCookie) {
      return false;
    }

    return previewModeCookie === true;
  }, []);

  const handleSwitchChange = React.useCallback(() => {
    // make page opacity 0.5
    document.body.style.opacity = '0.5';

    if (isPreviewModeActive || switchOn) {
      setSwitchOn(false);
      window.location.href =
        '/edge/preview?exit=true&redirect=' + window.location.pathname;
    } else {
      setSwitchOn(true);
      window.location.href =
        '/edge/preview?redirect=' + window.location.pathname;
    }
  }, [switchOn, isPreviewModeActive]);

  React.useEffect(() => {
    if (persistentState.session) {
      if (isPreviewModeActive) {
        persistentState.session.set({
          key: PERSISTENT_STATE_KEYS.previewMode,
          value: true,
        });
      } else {
        persistentState.session.delete({
          key: PERSISTENT_STATE_KEYS.previewMode,
        });
      }
    }
  }, [isPreviewModeActive]);

  const label = React.useMemo(() => {
    if (window.innerWidth < 500) {
      return '';
    }

    return breakpoint === 'xs' ? 'Preview' : 'Preview Mode';
  }, [breakpoint, typeof window !== 'undefined' && window.innerWidth]);

  if (!isPathEnabled(pathname)) {
    return null;
  }

  return (
    <Wrapper>
      <BlinkingText isPreviewModeActive={isPreviewModeActive}>
        {label}
      </BlinkingText>
      <Switch
        checked={isPreviewModeActive || switchOn}
        onChange={handleSwitchChange}
        color="primary"
      />
      {/* <span>{isPreviewMode ? 'Preview Mode ON' : 'Preview Mode OFF'}</span> */}
    </Wrapper>
  );
}
