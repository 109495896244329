'use client';
import React from 'react';

import { Tooltip } from '@mui/material';
import { usePathname } from 'next/navigation';
import { useLocale, useTranslations } from 'next-intl';
import styled from 'styled-components';

import Icon from '@/ui/components/Icon';
import SearchWithSuggestions from '@/ui/components/SearchInput';
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  padding: 2rem;
  /* blure */
  backdrop-filter: blur(3px);
  /* white glare oround */

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const Glow = styled.div`
  box-shadow: 5px 5px 60px 40px rgba(255, 255, 255, 1);
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 45.625rem;
  outline: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 0.3rem;
`;

const OverlayWithSearchField = ({
  children,
  onClickAction,
}: {
  children: React.ReactNode;
  onClickAction: () => void;
}) => {
  const locale = useLocale();
  const translate = useTranslations();
  const overlayRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // focus input element which is child of the overlay
    if (overlayRef.current) {
      const input = overlayRef.current.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  }, []);

  return (
    <Overlay
      ref={overlayRef}
      onClick={event => event.target === overlayRef.current && onClickAction()}
    >
      {children}
      <Glow>
        <SearchWithSuggestions
          locale={locale}
          placeholder={translate('homepage-search')}
        />
      </Glow>
    </Overlay>
  );
};

const CloseButton = styled(Icon).attrs({
  name: 'close',
})<{ onClick: () => void }>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  font-size: 2rem;
  padding: 0.5rem;

  &:hover {
    opacity: 0.5;
  }
`;

export default function OpenSearchButton() {
  const [overlayOpen, setOverlayOpen] = React.useState(false);
  const translate = useTranslations();
  const pathname = usePathname();

  // close overlay on escape key
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setOverlayOpen(false);
      }

      if (event.key === 'K' && event.shiftKey && event.ctrlKey) {
        event.preventDefault();
        event.stopPropagation();
        setOverlayOpen(!overlayOpen);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // if path contains /search then close
  React.useEffect(() => {
    setOverlayOpen(false);
  }, [pathname]);

  return (
    <>
      <Wrapper>
        <Tooltip
          title={`${translate('page-title-search')} (Ctrl + Shift + K)`}
          placement="bottom"
          arrow
        >
          <Icon name="search" onClick={() => setOverlayOpen(!overlayOpen)} />
        </Tooltip>
      </Wrapper>
      {overlayOpen && (
        <OverlayWithSearchField onClickAction={() => setOverlayOpen(false)}>
          <CloseButton name="close" onClick={() => setOverlayOpen(false)} />
        </OverlayWithSearchField>
      )}
    </>
  );
}
