'use client';
import React from 'react';

import { StyledLink } from './index.styled';
import { LOCALE_TO_LANGUAGE, LOCALES } from '@/i18n';
// import Link from '@/ui/components/Link';

export const usePrepareOptions = ({
  languageOptions,
  changeLanguage,
  pathname,
}) => {
  return React.useMemo(() => {
    if (languageOptions) {
      return languageOptions.map(option => ({
        label: LOCALE_TO_LANGUAGE[option.locale],
        action: () => changeLanguage(option.path),
      }));
    }

    const link = `/${pathname.replace(/^\/(en|cs|pl|de|fr|es|it|ja)/, '')}`;

    return LOCALES.map(locale => ({
      label: (
        <StyledLink href={`/${locale}${link}`}>
          <span style={{ color: 'black' }}>{LOCALE_TO_LANGUAGE[locale]}</span>
        </StyledLink>
      ),
      action: () => {},
    }));
  }, [languageOptions, changeLanguage, pathname]);
};
