'use client';
import React from 'react';

import { Menu, MenuItem } from '@mui/material';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

type Item = {
  label: string;
  action: () => void;
};

interface HoverMenuProps {
  children: React.ReactNode;
  items: Item[] | undefined;
}

/**
 * @todo translate
 * @todo add timeout
 */
export default function HoverMenu({ children, items }: HoverMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMouseEnter = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper
      onMouseEnter={handleMouseEnter}
      tabIndex={0}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
    >
      {children}
      {items && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMouseLeave}
          MenuListProps={{
            onMouseEnter: () => setAnchorEl(anchorEl),
            onMouseLeave: handleMouseLeave,
          }}
        >
          {items.map(({ label, action }) => (
            <MenuItem key={label} onClick={action}>
              {label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Wrapper>
  );
}
