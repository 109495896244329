'use client';
import React from 'react';

import { FormControl } from '@mui/material';
import { usePathname, useRouter } from 'next/navigation';
import { useLocale } from 'next-intl';

import { CurrentLanguage } from './index.styled';
import { usePrepareOptions } from './usePrepareOptions';
import { LOCALE_TO_LANGUAGE } from '@/i18n';
import { useGlobalState } from '@/ui/components/GlobalStateProvider';
import HoverMenu from '@/ui/components/HoverMenu';
import Icon from '@/ui/components/Icon';

export default function LanguageSelector() {
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();

  const { languageOptions } = useGlobalState();

  const changeLanguage = React.useCallback(
    (path: string) => {
      router.push(path);
    },
    [router]
  );

  const items = usePrepareOptions({
    languageOptions,
    changeLanguage,
    pathname,
  });

  return (
    <FormControl>
      <HoverMenu items={items}>
        <CurrentLanguage>
          {LOCALE_TO_LANGUAGE[locale]}
          <Icon name="chevron-down" style="solid" size="xs" />
        </CurrentLanguage>
      </HoverMenu>
    </FormControl>
  );
}