'use client';

import React from 'react';

import { baseTheme } from '@/theme';
import { isBrowser } from '@/utils/isBrowser';

const DEFAULT_BREAKPOINT = 'lg';

const getBreakpoint = (width?: number) => {
  let pageWidth = width;

  if (!isBrowser()) {
    return DEFAULT_BREAKPOINT;
  }

  if (!pageWidth) {
    if (isBrowser()) pageWidth = window.innerWidth;
    else return DEFAULT_BREAKPOINT;
  }

  const { sm, md, lg, xl } = baseTheme.breakpoints.values;

  if (pageWidth >= xl) return 'xl';
  if (pageWidth >= lg) return 'lg';
  if (pageWidth >= md) return 'md';
  if (pageWidth >= sm) return 'sm';
  return 'xs';
};

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = React.useState(getBreakpoint());

  // React.useEffect(() => {
  //   const handleResize = () => {
  //     setBreakpoint(getBreakpoint());
  //   };

  //   window.addEventListener('resize', handleResize);
  //   window.addEventListener('load', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //     window.removeEventListener('load', handleResize);
  //   };
  // }, []);

  // use resize observer
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setBreakpoint(getBreakpoint(entry.contentRect.width));
      }
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return breakpoint;
};
