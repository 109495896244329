'use client';
import React from 'react';

import styled from 'styled-components';

import LanguageSelector from './LanguageSelector';
import OpenSearchButton from './OpenSearchButton';
import UserMenu from './UserMenu';
import LayoutContainer from '@/ui/containers/layout/LayoutContainer';

const GrayStrip = styled.div<{ $isScrolled: boolean }>`
  position: sticky;
  top: 0;
  z-index: 112233;
  background-color: ${({ theme }) => theme.palette.gray.light};
  height: 2.5rem;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.3s ease;
  box-shadow: ${({ $isScrolled, theme }) =>
    $isScrolled
      ? `calc(100vw - 20px) 0 0 ${theme.palette.gray.light}, 0 -996px 8px 1000px rgba(0, 0, 0, 0.1)`
      : `calc(100vw - 20px) 0 0 ${theme.palette.gray.light}`}; // material table horzontal scroll fix
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1.88rem;

  svg {
    height: 1em;
  }
`;

const UserToolbar: React.FC = () => {
  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <GrayStrip $isScrolled={isScrolled}>
      <LayoutContainer
        sx={{
          paddingBottom: 0,
        }}
      >
        <Wrapper>
          <LanguageSelector />
          <OpenSearchButton />
          <UserMenu />
        </Wrapper>
      </LayoutContainer>
    </GrayStrip>
  );
};

export default UserToolbar;
