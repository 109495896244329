import React, { Dispatch, SetStateAction } from 'react';

export type LanguageOption = { locale: string; path: string };

interface GlobalStateContextInterface {
  languageOptions: null | LanguageOption[];
  setLanguageOptions: Dispatch<SetStateAction<LanguageOption[]>>;
}

export const initialState = {
  languageOptions: null,
  setLanguageOptions: () => {},
};

export const GlobalStateContext =
  React.createContext<GlobalStateContextInterface>(initialState);
export const GlobalStateDispatchContext = React.createContext({});
