'use client';
// import request from '@/utils/queryData/rest/clientRequest';
import { endpoints } from '@/utils/queryData/rest/endpoints';

/**
 * @todo refactor
 */
const deleteSession = () => {
  if (!process.browser) return null;
  sessionStorage.removeItem('prusuki_preview_mode');
  sessionStorage.removeItem('jwt_token'); // ### added
  sessionStorage.removeItem('prusuki_current_user');
  // delete jwt cookie
  document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

/**
 * @todo refactor
 */
function isUrlValid(input) {
  let valid;
  let url;

  try {
    url = new URL(input);
    valid = true;
  } catch {
    valid = false;
  }

  if (valid && url.protocol === 'https:') return true;

  return false;
}

export function logout() {
  // new request('logout')
  // .get({})
  fetch(endpoints.logout)
    .then(response => response.text())
    .then(data => {
      deleteSession();
      if (isUrlValid(data) && process.browser) window.location.assign(data);
      else window.location.reload();
    })
    .catch(error => {
      console.error(error);
    });
}
