'use client';
import React from 'react';

// import { usePathname } from 'next/navigation';

import {
  // initialState,
  LanguageOption,
  GlobalStateContext,
  // GlobalStateDispatchContext,
} from './context';
// import { reducer, ACTIONS } from './reducer';

export const GlobalStateProvider = ({ children }) => {
  // const path = usePathname();
  // dispatch] = React.useReducer(reducer, initialState);
  const [languageOptions, setLanguageOptions] = React.useState<
    LanguageOption[] | null
  >(null);

  React.useEffect(() => {
    setLanguageOptions(null);
  }, [typeof window !== 'undefined' && window.location.pathname]);

  return (
    <GlobalStateContext.Provider
      value={{ languageOptions, setLanguageOptions }}
    >
      {/* <GlobalStateDispatchContext.Provider value={{ setLanguageOptions }}> */}
      {children}
      {/* </GlobalStateDispatchContext.Provider> */}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => React.useContext(GlobalStateContext);

export default GlobalStateProvider;
