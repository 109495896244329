'use client';
import React from 'react';

import { useTranslations } from 'next-intl';

import { logout } from './logout';
import HoverMenu from '@/ui/components/HoverMenu';

/**
 * @todo translate
 */
export default function UserHoverMenu({ children }) {
  const translate = useTranslations();
  /**
   * @todo refactor - env
   */
  const manageAccount = React.useCallback(() => {
    const currentPath = window.location.pathname;
    const accountUrl = `https://account.prusa3d.com/profile-edit?next=${process.env.NEXT_PUBLIC_ORIGIN}${currentPath}`;

    window.location.href = accountUrl;
  }, []);

  const items = React.useMemo(
    () => [
      {
        label: translate('userMenu-manageAccount'),
        action: manageAccount,
      },
      {
        label: translate('userMenu-logout'),
        action: logout,
      },
    ],
    [manageAccount]
  );

  return <HoverMenu items={items}>{children}</HoverMenu>;
}
