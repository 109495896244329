'use client';
import React from 'react';

import { Avatar } from '@mui/material';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import styled from 'styled-components';

import PreviewModeSwitch from './PreviewModeSwitch';
import UserHoverMenu from './UserHoverMenu';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useAuthContext } from '@/ui/components/AuthProvider/context';

const Wrapper = styled.div<{ breakpoint: string }>`
  display: flex;
  align-items: center;
  gap: ${({ breakpoint }) => (breakpoint === 'xs' ? '0.8rem' : '1.88rem;')};
`;

const StyledAvatar = styled(Avatar)`
  width: 1.7rem;
  height: 1.7rem;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const LinkToAdmin = styled.a`
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

/**
 * @todo translate
 */
const UserMenu = () => {
  const { can, actions, isUserLoggedIn, user } = useAuthContext(); // isMedical
  const breakpoint = useBreakpoint();
  const pathname = usePathname();

  const username = React.useMemo(() => {
    if (!user) return '';

    let name =
      breakpoint === 'xs'
        ? `${user.lastName}`
        : `${user.firstName} ${user.lastName}`;

    if (typeof window !== 'undefined' && window.innerWidth < 300) {
      name = '';
    }

    return name;
  }, [breakpoint, user, typeof window !== 'undefined' && window.innerWidth]);

  const loginUrl = React.useMemo(() => {
    // const currentUrl = window.location.pathname;
    return `${process.env.NEXT_PUBLIC_LOGIN_URL}?redirect=${pathname}
    `;
  }, [pathname]);

  if (!isUserLoggedIn || !user) {
    return <Link href={loginUrl}>Login</Link>;
  }

  return (
    <Wrapper breakpoint={breakpoint}>
      {can(actions.preview) && <PreviewModeSwitch />}
      <UserHoverMenu>
        <UserWrapper>
          <StyledAvatar alt={`${user.firstName.charAt(0)}`} src={user.avatar} />
          <span className="username">{username}</span>
        </UserWrapper>
      </UserHoverMenu>
      {/* <div>{JSON.stringify({ isMedical })}</div> */}
      {can(actions.visit_admin) && (
        <LinkToAdmin href="/admin">Admin</LinkToAdmin>
      )}
    </Wrapper>
  );
};

export default UserMenu;
