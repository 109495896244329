import React from 'react';

import { Breakpoint, Container } from '@mui/material';

// import { MAX_WIDTH } from '@/utils/breakpoints';

interface MaxWidth {
  breakpointName: Breakpoint;
  breakpointValue: number;
}

const MAX_WIDTH: MaxWidth = {
  breakpointName: 'lg',
  breakpointValue: 1280,
};

const LayoutContainer = ({ children, sx = {}, noPadding = false }) => {
  return (
    <Container
      disableGutters={true}
      maxWidth={MAX_WIDTH.breakpointName}
      sx={{
        // height: '100%', // fuckups with prusa footer
        paddingBottom: noPadding ? '0' : '6.25rem',
        maxWidth: `${MAX_WIDTH.breakpointValue}px !important`, // TODO: maxWidth wrongly apply 1200px
        px: noPadding ? '0' : '20px',
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};

export default LayoutContainer;
