import styled from 'styled-components';

export const CurrentLanguage = styled.div`
  color: #808080;
  font-family: 'Atlas Grotesk';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  gap: 0.2rem;
  justify-content: center;
  align-items: center;
`;

export const StyledLink = styled.a`
  color: black !important;
  text-decoration: none;
`;