import { Breakpoint } from '@mui/material/styles';

import { baseTheme } from '@/theme';

interface maxWidth {
  breakpointName: Breakpoint;
  breakpointValue: number;
}

export const MAX_WIDTH: maxWidth = {
  breakpointName: 'lg',
  breakpointValue: baseTheme.breakpoints.values.lg,
};
