import PrusaNavigation from '@prusa3d-platform/prusa-main-navigation';
import { useLocale } from 'next-intl';
import styled from 'styled-components';

import { MAX_WIDTH } from '@/utils/breakpoints';

const NavigationWrapper = styled.div`
  position: relative;
  z-index: 33;
`;

/**
 * @todo allow navigation module to export types
 */
type WebsiteType = 'help' | 'shop' | 'forum' | 'blog';

interface PrusaNavigationOptions {
  maxWidth: string;
  website: WebsiteType;
}

const prusaNavigationOptions: PrusaNavigationOptions = {
  maxWidth: `${MAX_WIDTH.breakpointValue}px`,
  website: 'help',
};

export default function MainNavigation() {
  const locale = useLocale();

  return (
    <NavigationWrapper>
      <PrusaNavigation locale={locale} config={prusaNavigationOptions} />
    </NavigationWrapper>
  );
}
